import React from "react";
import styles from "./styles.module.css";
import {
  passwordСhanging,
  clearErrorPassword,
  clearSuccessChanging,
} from "../../store/login";
import {
  getErrorPassword,
  getResetLoading,
  getSuccessChanging,
} from "../../store/login/selectors";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../images/iconLogoLogin.svg";
import displayErrors from "../../utils/displayErrors";
import { Form, Input, Button, Typography, Tooltip, Spin } from "antd";
import { useLocation, Navigate } from "react-router-dom";
import Help from "../../images/iconInfo.svg";

export const ChangingPassword = () => {
  const dispatch = useDispatch();
  const error = useSelector(getErrorPassword);
  const isLoading = useSelector(getResetLoading);
  const success = useSelector(getSuccessChanging);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const onFinish = (values) => {
    const { password, confirmPassword } = values;

    if (password !== confirmPassword) {
      return;
    }

    dispatch(passwordСhanging({ password: password, token: token }));
    dispatch(clearErrorPassword());
    dispatch(clearSuccessChanging());
  };

  return (
    <>
      {success && <Navigate to="/login" replace={true} />}
      <div className={styles.login}>
        <img src={Logo} alt="logo" />
        <Form
          onFinish={onFinish}
          name="reset"
          className={styles.containerInputs}
          layout="vertical"
          requiredMark={false}
        >
          {isLoading && <Spin size="large" />}
          {error && displayErrors(error)}
          <Typography.Text className={styles.text}>
            Изменение пароля
          </Typography.Text>
          <Form.Item
            name="password"
            label="Введите новый пароль"
            style={{ margin: 0 }}
            labelCol={{ className: styles.label }}
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите новый пароль!",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder=""
              type="password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Подтвердите новый пароль"
            style={{ margin: 0 }}
            labelCol={{ className: styles.label }}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Пожалуйста, подтвердите новый пароль!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Введенные пароли не совпадают!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder=""
              type="password"
            />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Изменить
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

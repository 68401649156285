export const getFailsData = ({ fails }) => fails.fails;
export const getFailsLoading = ({ fails }) => fails.loaders.common;
export const getErrorEdits = ({ fails }) => fails.errors.edit;
export const getErrorCreate = ({ fails }) => fails.errors.create;
export const getErrorDelete = ({ fails }) => fails.errors.delete;
export const getError = ({ fails }) => fails.errors.error;
export const getSuccess = ({ fails }) => fails.success.success;
export const getStaffDropDownSelector = ({ fails }) => fails.staffDropDown;
export const getTypeDropDownSelector = ({ fails }) => fails.typeDropDown;
export const getOpenFailsSelector = ({ fails }) => fails.openFails;
export const getSuccessCreate = ({ fails }) => fails.success.create;
export const getSuccessEdit = ({ fails }) => fails.success.edit;
export const getFailsNextPage = ({ fails }) => fails.nextPage;

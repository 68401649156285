import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { postLogout, clearError } from "../../store/login";
import {
  getAuth,
  getError,
  getLoginLoading,
} from "../../store/login/selectors";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../images/iconLogoLogin.svg";
import displayErrors from "../../utils/displayErrors";
import { Form, Input, Button, Typography, Tooltip, Spin } from "antd";
import Help from "../../images/iconInfo.svg";
import { getUserData } from "../../store/user/selectors";
import { getStudioMenuSelector } from "../../store/studios/selectors";

export const Login = () => {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const auth = useSelector(getAuth);
  const navigate = useNavigate();
  const isLoading = useSelector(getLoginLoading);
  const userData = useSelector(getUserData);
  const studioMenuTitle = useSelector(getStudioMenuSelector);

  const onFinish = (values) => {
    dispatch(postLogout(values)).then((response) => {
      if (response.type !== "postLogout/rejected") {
        navigate(
          `/complaints/${
            studioMenuTitle?.y_id || userData?.user_studios[0]?.y_id
          }`
        );
      }
    });
    dispatch(clearError());
  };

  return (
    <>
      {auth && (
        <Navigate
          to={`/complaints/${userData?.user_studios[0]?.y_id}`}
          replace={true}
        />
      )}
      <div className={styles.login}>
        <img src={Logo} alt="logo" />
        <Form
          onFinish={onFinish}
          name="auth"
          className={styles.containerInputs}
          layout="vertical"
          requiredMark={false}
        >
          {error && displayErrors(error)}
          {isLoading && <Spin size="large" />}
          <Typography.Text className={styles.text}>
            Вход в систему
          </Typography.Text>
          <Form.Item
            name="username"
            label="Логин"
            style={{ margin: 0 }}
            labelCol={{ className: styles.label }}
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите логин!",
              },
            ]}
          >
            <Input
              className={styles.input}
              placeholder="7-xxx-xxx-xx-xx"
              suffix={
                <Tooltip
                  color="#FFFFFF"
                  title={
                    <span style={{ color: "#4B4F55" }}>
                      {"Введите ваш логин"}
                    </span>
                  }
                >
                  <img src={Help} alt="help" style={{ cursor: "pointer" }} />
                </Tooltip>
              }
              onBeforeInput={(e) => {
                if (!/\d/.test(e.data)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ margin: 0 }}
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль!",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              autoComplete="new-password"
              placeholder="**********"
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "end" }}>
            <Typography.Text
              className={styles.reset}
              onClick={() => {
                navigate("/reset");
                dispatch(clearError());
              }}
            >
              Восстановить пароль
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

import { useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Complaints } from "../../pages/Complaints";
import { Corrections } from "../../pages/Corrections";
import { Payments } from "../../pages/Payments";
import { ComplaintsNetwork } from "../../pages/ComplaintsNetwork";
import { CorrectionsNetwork } from "../../pages/CorrectionsNetwork";
import { Login } from "../../pages/Login";
import { ResetPassword } from "../../pages/ResetPassword";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { Layout } from "antd";
import { LayoutPage } from "../LayoutPage";
import { ChangingPassword } from "../../pages/ChangingPassword";
import { SettingsStudio } from "../../pages/SettingsStudio";
import { UserManagement } from "../../pages/UserManagement";
import { TypesComplaints } from "../../pages/TypesComplaints";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setClearUser } from "../../store/user";
import { getUserData } from "../../store/user/selectors";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("accessToken");
  const location = useLocation();
  const userData = useSelector(getUserData);

  useEffect(() => {
    dispatch(getUser())
      .then(unwrapResult)
      .catch((error) => {
        if (error.data.errors.some((err) => err.code === "token_not_valid")) {
          navigate("/login");
          dispatch(setClearUser());
        }
      });
  }, [jwt]);

  useEffect(() => {
    const jwt = localStorage.getItem("accessToken");
    if (!jwt && !["/reset", "/changing"].includes(location.pathname)) {
      navigate("/login");
      dispatch(setClearUser());
    }
  }, [jwt, userData]);

  useEffect(() => {
    if (
      performance.navigation.type === 1 &&
      userData &&
      userData.user_studios &&
      userData.user_studios.length > 0
    ) {
      const defaultStudioId = userData.user_studios[0].y_id;
      navigate(`/complaints/${defaultStudioId}`);
    }
  }, [userData]);

  return (
    <Layout
      style={{
        height: "100vh",
        gap: "10px",
        backgroundColor: "#F9F9F9",
        position: "relative",
      }}
    >
      <Routes>
        <Route path="/" element={<Navigate to={`/complaints/:id`} />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/reset"
          element={
            <ProtectedRoute>
              <ResetPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="complaints">
                <Complaints />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/corrections/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="corrections">
                <Corrections />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payments/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="payments">
                <Payments />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="settings">
                <SettingsStudio />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/changing"
          element={
            <ProtectedRoute>
              <ChangingPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user_management"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="user">
                <UserManagement />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/types_complaints"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="types">
                <TypesComplaints />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="complaintsNetwork">
                <ComplaintsNetwork />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/corrections_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="correctionsNetwork">
                <CorrectionsNetwork />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;

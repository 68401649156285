import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getStudioMenuSelector } from "../../store/studios/selectors";
import IconQuality from "../../images/iconQuality.svg";
import IconArrowUp from "../../images/iconArrowUp.svg";
import IconArrowDown from "../../images/iconArrowDown.svg";
import IconSalary from "../../images/iconSalary.svg";
import styles from "./styles.module.css";

export const SidebarNetwork = ({
  pageId,
  openMenu,
  openSidebar,
  openQuality,
  setOpenQuality,
  openSalaries,
  setOpenSalaries,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  const studioMenuTitle = useSelector(getStudioMenuSelector);

  return openSidebar ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0 12px",
      }}
    >
      <div key="quality" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openQuality && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenQuality(!openQuality);
            setOpenSalaries(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconQuality} alt="Качество" />
            <span>Качество</span>
          </div>
          {!openQuality ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openQuality && (
          <div
            style={{
              padding: "14px 0 23px 18px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openQuality && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "complaintsNetwork" && styles.customLinkSelected
              }`}
              to={`/complaints_network/${studioMenuTitle?.y_id}`}
            >
              Жалобы сети
            </Link>
          </div>
        )}
      </div>
      <div key="salaries" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openSalaries && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenSalaries(!openSalaries);
            setOpenQuality(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
            <span>Зарплаты</span>
          </div>
          {!openSalaries ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openSalaries && (
          <div
            style={{
              padding: "14px 0 23px 18px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openSalaries && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "correctionsNetwork" && styles.customLinkSelected
              }`}
              to={`/corrections_network/${studioMenuTitle?.y_id}`}
            >
              Коррекции сети
            </Link>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0",
      }}
    >
      <div
        key="quality"
        onMouseEnter={() => handleMouseEnter("quality")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "complaintsNetwork" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconQuality} alt="Качество" />
          </div>
        </div>
        {openMenu === "quality" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "quality" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "quality" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "complaintsNetwork" && styles.customLinkSelected
                }`}
                to={`/complaints_network/${studioMenuTitle?.y_id}`}
              >
                Жалобы сети
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="salaries"
        onMouseEnter={() => handleMouseEnter("salaries")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "correctionsNetwork" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
          </div>
        </div>
        {openMenu === "salaries" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "salaries" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "salaries" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "correctionsNetwork" && styles.customLinkSelected
                }`}
                to={`/corrections_network/${studioMenuTitle?.y_id}`}
              >
                Коррекции сети
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import {
  Input,
  Button,
  Table,
  Badge,
  Select,
  Space,
  Spin,
  Row,
  Col,
  Tooltip,
  Avatar,
} from "antd";
import { DatePickerPopover } from "../../components/DatePickerPopover";
import { ModalComponent } from "../../components/ModalComponent";
import { setOpenInfo } from "../../store/user";
import { InfoModal } from "../../components/InfoModal";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  isInclude,
  convertToLocalDate,
  convertDateToISO861,
  selectDropdown,
  updateFilters,
  updateFiltersArr,
} from "../../utils/utils";
import filters from "../../images/filters.svg";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorStudio } from "../../store/studios";
import {
  getStudioData,
  getErrorStudio,
  getStudioMenuSelector,
} from "../../store/studios/selectors";
import { getOpenInfoSelector } from "../../store/user/selectors";
import {
  getPayments,
  deletePayments,
  clearError,
  clearSuccess,
} from "../../store/payments";
import { getStaffDropDown } from "../../store/staff";
import { getStaffDropDownSelector } from "../../store/staff/selectors";
import {
  getPaymentsData,
  getPaymentsLoading,
  getError,
  getOpenFailsSelector,
  getSuccess,
  getSuccessCreate,
  getSuccessEdit,
  getPaymentsNextPage,
} from "../../store/payments/selectors";
import displayErrors from "../../utils/displayErrors";
import { CraeteFailsModal } from "../../components/CraeteFailsModal";
import { EditFailsModal } from "../../components/EditFailsModal";

export const Payments = () => {
  const dispatch = useDispatch();
  const studioData = useSelector(getStudioData);
  const error = useSelector(getError);
  const errorStudio = useSelector(getErrorStudio);
  const paymentsData = useSelector(getPaymentsData);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedCorrections, setSelectedCorrections] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedYclient, setSelectedYclient] = useState([]);
  const [includeStaff, setIncludeStaff] = useState(true);
  const [includeYclient, setIncludeYclient] = useState(true);
  const [includeCorrections, setIncludeCorrections] = useState(true);
  const [fileSearch, setFileSearch] = useState("");
  const [filtersArray, setFiltersArray] = useState([]);
  const [openFiltersRow, setOpenFiltersRow] = useState(false);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [openDate, setOpenDate] = useState(false);
  const [viewDataFilter, setViewDataFilter] = useState(null);
  const isLoadingPayments = useSelector(getPaymentsLoading);
  const staffList = useSelector(getStaffDropDownSelector);
  const successCreate = useSelector(getSuccessCreate);
  const successEdit = useSelector(getSuccessEdit);
  const openFailsCreate = useSelector(getOpenFailsSelector);
  const openInfo = useSelector(getOpenInfoSelector);
  const [openFailsEdit, setOpenFailsEdit] = useState(false);
  const [openPaymentsDelete, setOpenPaymentsDelete] = useState(false);
  const success = useSelector(getSuccess);
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getPaymentsNextPage);
  const studioMenuTitle = useSelector(getStudioMenuSelector);
  const lastItem = useRef();
  const observer = useRef();

  const columns = [
    createColumn(
      "Id выплаты",
      "id",
      (v) => {
        return <span style={{ color: "#4B4F55" }}>{v}</span>;
      },
      70
    ),
    createColumn(
      "Период расчета",
      null,
      (_, row) => {
        let valueStart = convertToLocalDate(row?.pay_date_time);
        let valueEnd = convertToLocalDate(row?.period_end_date);
        const [dateStart] = valueStart.split(" ");
        const [dateEnd] = valueEnd.split(" ");
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55" }}>с {dateStart}</span>
            <span style={{ color: "#4B4F55" }}>по {dateEnd}</span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Mастер",
      "master",
      (_, row) => {
        return (
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <Avatar
              style={{ width: 25, minWidth: 25, height: 25 }}
              src={row?.master?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span>{row?.master?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                }}
              >
                {row?.master?.y_specialization}
              </span>
            </Space>
          </div>
        );
      },
      100
    ),
    createColumn(
      "Дата выплаты",
      "pay_date_time",
      (v) => {
        let value = convertToLocalDate(v);
        const [date, time] = value.split(" ");
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ color: "#4B4F55" }}>{date}</span>
            <span style={{ color: "#7A7D86" }}>{time}</span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Сумма",
      "paid",
      (v) => {
        return <span>{v}</span>;
      },
      100
    ),
    createColumn(
      "Коррекции в выплате",
      "related_corrections",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "Добавлено в YClients",
      "is_post_yclient",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Успешно" />
        ) : (
          <img src={iconNotSuccessful} alt="Не успешно" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить выплату</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenPaymentsDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];

  const y_client = [
    { name: "Внесено", bol: true },
    { name: "Не внесено", bol: false },
  ];

  function createColumn(title, key, render, width, align) {
    return { title, key, dataIndex: key, render, width, align };
  }

  const changeArrayFilters = ({ key, value, text, title }) => {
    const isIncludes = (el, filterName) => {
      return el.key.includes(filterName) !== key.includes(filterName);
    };

    let filtersResult = filtersArray.filter((i) => {
      return isIncludes(i, "master");
    });

    setFiltersArray(
      value || value === 0
        ? [
            ...filtersResult,
            { key: key, value: value, text: text, title: title },
          ]
        : filtersResult
    );
  };

  const handleChangeDateAfter = (e) => {
    if (e?.$d) {
      setDateAfterFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateBeforeFilter) {
        setViewDataFilter(
          e?.$d.toLocaleDateString("ru-RU") + " - " + dateBeforeFilter
        );
      } else {
        setViewDataFilter(e?.$d.toLocaleDateString("ru-RU") + " - ");
      }
    } else {
      if (dateAfterFilter) {
        setViewDataFilter(dateBeforeFilter);
      }
      setDateAfterFilter(null);
    }
  };

  const handleChangeDateBefore = (e) => {
    if (e?.$d) {
      setDateBeforeFilter(e?.$d.toLocaleDateString("ru-RU"));
      if (dateAfterFilter) {
        setViewDataFilter(
          dateAfterFilter + " - " + e?.$d.toLocaleDateString("ru-RU")
        );
      } else {
        setViewDataFilter(" - " + e?.$d.toLocaleDateString("ru-RU"));
      }
    } else {
      if (dateBeforeFilter) {
        setViewDataFilter(dateAfterFilter);
      }
      setDateBeforeFilter(null);
    }
  };

  const fetchData = (pagination = false) => {
    let filters = false;
    let offsetValue = null;
    let limitValue = null;

    if (dateAfterFilter || dateBeforeFilter) {
      filters = {
        start_date: convertDateToISO861(dateAfterFilter),
        end_date: convertDateToISO861(dateBeforeFilter),
      };
    }

    // if (pagination && nextPage) {
    //   const urlParams = new URLSearchParams(nextPage.split("?")[1]);
    //   offsetValue = urlParams.get("offset");
    // }

    if (pagination && nextPage) {
      const urlParams = new URLSearchParams(nextPage.split("?")[1]);
      limitValue = Number(urlParams.get("limit")) + 25;
    }

    dispatch(
      getPayments({
        studio_y_id: studioMenuTitle?.y_id || studioData?.y_id,
        filters: filters,
        filtersArray: filtersArray,
        // cursor: pagination ? offsetValue : null,
        limit: pagination ? limitValue : null,
        pagination: pagination,
      })
    );
  };

  const clearFilters = () => {
    setSelectedCorrections(null);
    setSelectedStaff([]);
    setSelectedYclient([]);
    setFileSearch("");
    setIncludeStaff(true);
    setIncludeYclient(true);
    setIncludeCorrections(true);
    setViewDataFilter(null);
    setDateBeforeFilter(null);
    setDateAfterFilter(null);
    setFiltersArray([]);
  };

  useEffect(() => {
    dispatch(clearErrorStudio());
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (studioData) {
      dispatch(getStaffDropDown(studioData?.y_id));
    }
  }, [studioData?.y_id]);

  useEffect(() => {
    updateFiltersArr(
      "master",
      includeStaff,
      selectedStaff,
      setFiltersArray,
      filtersArray
    );
  }, [includeStaff, selectedStaff]);

  useEffect(() => {
    updateFilters(
      "has_corrections",
      includeCorrections,
      selectedCorrections,
      setFiltersArray,
      filtersArray
    );
  }, [includeCorrections, selectedCorrections]);

  useEffect(() => {
    updateFiltersArr(
      "is_post_yclient",
      includeYclient,
      selectedYclient,
      setFiltersArray,
      filtersArray
    );
  }, [includeYclient, selectedYclient]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    if (studioData) {
      const timerRefresh = setTimeout(fetchData, 0);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [
    dateAfterFilter,
    dateBeforeFilter,
    filtersArray,
    studioData,
    success,
    successCreate,
    successEdit,
  ]);

  useEffect(() => {
    if (isLoadingPayments) return;

    if (observer?.current) observer?.current.disconnect();

    let callback = function (
      entries
      //observer
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        paymentsData?.data?.results?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };
    observer.current = new IntersectionObserver(callback);
    if (lastItem?.current instanceof Element) {
      observer.current.observe(lastItem.current);
    } else {
      console.warn("lastItem.current is not a valid DOM element");
    }
  }, [isLoadingPayments]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [width]);

  useEffect(() => {
    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const firstDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth,
      1
    );
    const lastDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      previousMonth + 1,
      0
    );

    setDateAfterFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0]
    );
    setDateBeforeFilter(
      convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
    setViewDataFilter(
      convertToLocalDate(firstDayOfPreviousMonth).split(" ")[0] +
        " - " +
        convertToLocalDate(lastDayOfPreviousMonth).split(" ")[0]
    );
  }, []);

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            gap: width > 640 ? 12 : 9,
            position: "relative",
            paddingBottom: width > 640 ? 25 : 16,
          }}
        >
          <DatePickerPopover
            openDate={openDate}
            setOpenDate={setOpenDate}
            viewDataFilter={viewDataFilter}
            dateAfterFilter={dateAfterFilter}
            dateBeforeFilter={dateBeforeFilter}
            // handleSetDateFilters={handleSetDateFilters}
            handleChangeDateBefore={handleChangeDateBefore}
            handleChangeDateAfter={handleChangeDateAfter}
          />

          {width > 640 && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                style={{ borderRadius: "2px" }}
                prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                value={fileSearch}
                placeholder="Поиск по имени мастера, сумме, id выплаты"
                suffix={
                  <CloseOutlined
                    style={{
                      color: fileSearch ? "red" : "black",
                    }}
                    onClick={() => {
                      setFileSearch("");
                      setFiltersArray(
                        filtersArray.filter((v) => v.key !== "q")
                      );
                    }}
                  />
                }
                onChange={(evt) => {
                  setFileSearch(evt.target.value);
                  changeArrayFilters({
                    item: "",
                    key: "q",
                    value: evt.target.value,
                    text: evt.target.value,
                    title: "q",
                  });
                }}
              />
            </Space>
          )}

          <Space.Compact style={{ alignSelf: "flex-end" }} title="Фильтры">
            <Button
              onClick={() => {
                setOpenFiltersRow(!openFiltersRow);
              }}
              style={{
                borderRadius: "2px",
                width: width > 640 ? "151px" : "67px",
                gap: "20px",
              }}
              icon={
                <Badge count={filtersArray.length} size="small" color="blue">
                  <img
                    style={{
                      color: "#6679A9",
                    }}
                    alt="filters"
                    src={filters}
                  />
                </Badge>
              }
            >
              {width > 640 && "Фильтры"}
            </Button>
          </Space.Compact>

          {(dateAfterFilter ||
            dateBeforeFilter ||
            filtersArray?.length > 0) && (
            <Button
              onClick={clearFilters}
              style={{ borderRadius: 2, backgroundColor: "#4D7CD6" }}
              type={"primary"}
            >
              Сбросить
            </Button>
          )}
        </div>

        {openFiltersRow && (
          <>
            {width < 640 && (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingBottom: 25 }}
              >
                <Input
                  style={{ borderRadius: "2px" }}
                  prefix={<SearchOutlined style={{ color: "#C1C2C9" }} />}
                  value={fileSearch}
                  placeholder="Поиск по имени мастера, сумме, id выплаты"
                  suffix={
                    <CloseOutlined
                      style={{
                        color: fileSearch ? "red" : "black",
                      }}
                      onClick={() => {
                        setFileSearch("");
                        setFiltersArray(
                          filtersArray.filter((v) => v.key !== "q")
                        );
                      }}
                    />
                  }
                  onChange={(evt) => {
                    setFileSearch(evt.target.value);
                    changeArrayFilters({
                      item: "",
                      key: "q",
                      value: evt.target.value,
                      text: evt.target.value,
                      title: "q",
                    });
                  }}
                />
              </Space>
            )}
            <div
              style={{
                display: width > 543 ? "flex" : "grid",
                rowGap: width > 640 ? 12 : 22,
                columnGap: width > 640 ? 12 : 7,
                gridTemplateColumns: width < 543 ? "repeat(2, 50%)" : "1fr",
                position: "relative",
                paddingBottom: width > 640 ? 20 : 10,
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeStaff)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  mode="multiple"
                  placeholder="Сотрудник"
                  style={{ width: "100%", textAlign: "left" }}
                  options={staffList?.results?.map((option) => {
                    return {
                      value: option.id,
                      label: option.y_name,
                    };
                  })}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeStaff, setIncludeStaff)
                  }
                  value={selectedStaff}
                  onChange={setSelectedStaff}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeYclient)}
                <Select
                  allowClear={true}
                  optionFilterProp="label"
                  placeholder="Внесено в Yclient"
                  mode="multiple"
                  options={y_client.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                  style={{ width: "100%" }}
                  dropdownRender={(menu) =>
                    selectDropdown(menu, includeYclient, setIncludeYclient)
                  }
                  value={selectedYclient}
                  onChange={setSelectedYclient}
                />
              </Space>

              <Space direction="vertical" style={{ width: "100%" }}>
                {isInclude(includeCorrections)}
                <Select
                  allowClear={true}
                  maxTagCount="responsive"
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder="Коррекции"
                  value={selectedCorrections}
                  onChange={setSelectedCorrections}
                  dropdownRender={(menu) =>
                    selectDropdown(
                      menu,
                      includeCorrections,
                      setIncludeCorrections
                    )
                  }
                  options={y_client.map((v) => {
                    return { value: v.bol, label: v.name };
                  })}
                />
              </Space>
            </div>
          </>
        )}
      </>

      <div
        style={{
          maxHeight:
            width > 640 ? "calc(100vh - 195px)" : "calc(100vh - 175px)",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(error || errorStudio) && displayErrors(error || errorStudio)}
        <Table
          columns={columns}
          rowKey={(obj) => obj.id}
          size="small"
          pagination={false}
          loading={isLoadingPayments && <Spin size="large" />}
          className={`${styles.table} ${styles.table_header}`}
          dataSource={paymentsData?.data?.results || []}
          // onRow={(record) => ({
          //   onClick: () => {
          //     setOpenFailsEdit({
          //       open: true,
          //       record: record,
          //     });
          //   },
          // })}
        />
        <div ref={lastItem} style={{ minHeight: 10, maxHeight: 10 }} />
      </div>
      {openFailsCreate && <CraeteFailsModal openFails={openFailsCreate} />}
      {openFailsEdit && (
        <EditFailsModal
          openFailsEdit={openFailsEdit}
          setOpenFailsEdit={setOpenFailsEdit}
        />
      )}
      {openPaymentsDelete.open && (
        <ModalComponent
          open={openPaymentsDelete.open}
          title="Вы уверены, что хотите удалить эту выплату?"
          body={
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                  }}
                  onClick={() => setOpenPaymentsDelete(false)}
                >
                  Не удалять
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: 48,
                    background: "var(--Primary-1-normal, #2E496F)",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      deletePayments({
                        studio_y_id: studioData?.y_id,
                        id: openPaymentsDelete.data.id,
                      })
                    ).then(() => {
                      dispatch(clearSuccess());
                      setOpenPaymentsDelete(false);
                    });
                  }}
                >
                  Удалить
                </Button>
              </Col>
            </Row>
          }
          onCancel={() => {
            setOpenPaymentsDelete(false);
          }}
        />
      )}
      {openInfo && (
        <InfoModal
          openModal={openInfo}
          setOpenModal={setOpenInfo}
          title={"Информация по жалобам"}
          text={"Тут будет информация"}
        />
      )}
    </>
  );
};

export const getOpenUserManagementSelector = ({ userManagement }) =>
  userManagement.openUserManagement;
export const getUserManagementData = ({ userManagement }) =>
  userManagement.settingsComplaints;
export const getUserManagementLoading = ({ userManagement }) =>
  userManagement.loaders.common;
export const getSuccessDelete = ({ userManagement }) =>
  userManagement.success.delete;
export const getSuccessPatch = ({ userManagement }) =>
  userManagement.success.patch;

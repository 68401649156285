export const getPaymentsData = ({ payments }) => payments.payments;
export const getPaymentsLoading = ({ payments }) => payments.loaders.common;
export const getErrorEdits = ({ fails }) => fails.errors.edit;
export const getErrorCreate = ({ fails }) => fails.errors.create;
export const getErrorDelete = ({ payments }) => payments.errors.delete;
export const getError = ({ payments }) => payments.errors.error;
export const getSuccess = ({ payments }) => payments.success.success;
export const getStaffDropDownSelector = ({ fails }) => fails.staffDropDown;
export const getTypeDropDownSelector = ({ fails }) => fails.typeDropDown;
export const getOpenFailsSelector = ({ fails }) => fails.openFails;
export const getSuccessCreate = ({ fails }) => fails.success.create;
export const getSuccessEdit = ({ fails }) => fails.success.edit;
export const getPaymentsNextPage = ({ payments }) => payments.nextPage;

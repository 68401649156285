import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

const checkFilters = (filters, filtersArray, cursor = false, limit = false) => {
  if (!filters) filters = {};

  if (filtersArray?.length > 0) {
    filtersArray?.map((item) => {
      if (item.key !== "start_date" && item.key !== "end_date") {
        if (Array.isArray(item.value)) {
          filters[item.key] = item.value.join(",");
        } else {
          filters[item.key] = item.value;
        }
      } else "";
    });
  }

  if (cursor !== false) {
    filters.offset = cursor;
  }

  if (limit !== false) {
    filters.limit = limit;
  }

  return filters;
};

export const getNetworkComplaints = createAsyncThunk(
  "getNetworkComplaints",
  async (
    { network_y_id, filters, filtersArray, limit, cursor, pagination },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/network_fails/network/${network_y_id}/fails/`,
        {
          params: checkFilters(filters, filtersArray, cursor, limit),
        }
      );
      return { data, pagination };
    } catch (err) {
      if (err.code === "ERR_NETWORK") return rejectWithValue(err);
      else return rejectWithValue(err.response);
    }
  }
);

const initialState = {
  networkComplaints: null,
  openComplaintsNetwork: false,
  nextPage: null,
  success: {
    success: false,
  },
  errors: {
    error: null,
  },
  loaders: {
    common: false,
  },
};

const networkComplaintsSlice = createSlice({
  name: "networkComplaintsSlice",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors.error = null;
    },
    setOpenComplaintsNetwork: (state) => {
      state.openComplaintsNetwork = !state.openComplaintsNetwork;
    },
  },

  extraReducers: (builder) => {
    // getNetworkComplaints
    builder.addCase(getNetworkComplaints.pending, (state) => {
      state.loaders.common = true;
      state.errors.error = null;
      state.nextPage = null;
    });
    builder.addCase(getNetworkComplaints.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.networkComplaints = payload;
      state.nextPage = payload?.data.next;
    });
    builder.addCase(getNetworkComplaints.rejected, (state, action) => {
      state.loaders.common = false;
      state.errors.error = action?.payload?.data;
    });
  },
});
export const { setOpenComplaintsNetwork, clearError } =
  networkComplaintsSlice.actions;
export default networkComplaintsSlice.reducer;

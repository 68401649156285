import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import {
  passwordReset,
  clearErrorPassword,
  clearSuccessReset,
} from "../../store/login";
import {
  getErrorPassword,
  getResetLoading,
  getSuccessReset,
} from "../../store/login/selectors";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../images/iconLogoLogin.svg";
import displayErrors from "../../utils/displayErrors";
import { Form, Input, Button, Typography, Tooltip, Spin, Col, Row } from "antd";
import { useNavigate, Navigate } from "react-router-dom";
import Help from "../../images/iconInfo.svg";
import { ModalComponent } from "../../components/ModalComponent";

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(getErrorPassword);
  const isLoading = useSelector(getResetLoading);
  const success = useSelector(getSuccessReset);
  const [openModal, setOpenModal] = useState(false);

  const onFinish = (values) => {
    dispatch(passwordReset(values));
    dispatch(clearErrorPassword());
  };

  useEffect(() => {
    if (success) {
      setOpenModal(true);
    }
  }, [success]);

  return (
    <>
      <div className={styles.login}>
        <img src={Logo} alt="logo" />
        <Form
          onFinish={onFinish}
          name="reset"
          className={styles.containerInputs}
          layout="vertical"
          requiredMark={false}
        >
          {isLoading && <Spin size="large" />}
          {error && displayErrors(error)}
          <Typography.Text className={styles.text}>
            Восстановление пароля
          </Typography.Text>
          <Form.Item
            name="email"
            label="Email из YClients для восстановления"
            style={{ margin: 0 }}
            labelCol={{ className: styles.label }}
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите aдрес эл. почты!",
              },
            ]}
          >
            <Input
              className={styles.input}
              placeholder=""
              type="email"
              suffix={
                <Tooltip
                  color="#FFFFFF"
                  title={
                    <span style={{ color: "#4B4F55" }}>
                      {"Введите ваш aдрес эл. почты"}
                    </span>
                  }
                >
                  <img src={Help} alt="help" style={{ cursor: "pointer" }} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Восстановить
            </Button>
          </Form.Item>
        </Form>
      </div>
      {openModal && (
        <ModalComponent
          open={openModal}
          title="На Вашу почту было отправлено письмо с ссылкой для восстановления пароля."
          body={
            <Row style={{ justifyContent: "center" }}>
              <Button
                style={{
                  width: "100%",
                  maxWidth: "186px",
                  borderRadius: 4,
                  height: 48,
                  background: "var(--Primary-1-normal, #2E496F)",
                }}
                type="primary"
                htmlType="submit"
                onClick={() => {
                  dispatch(clearSuccessReset());
                  setOpenModal(false);
                  navigate("/login");
                }}
              >
                Ок
              </Button>
            </Row>
          }
          onCancel={() => {
            dispatch(clearSuccessReset());
            setOpenModal(false);
            navigate("/login");
          }}
        />
      )}
    </>
  );
};

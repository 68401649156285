import React from "react";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getStudio,
  clearErrorStudio,
  setStudioMenu,
} from "../../store/studios";
import { getNetworks } from "../../store/networks";
import { clearError } from "../../store/fails";

export const MenuStudios = ({ data, setOpenStudios }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <div
        className={styles.background}
        onClick={() => setOpenStudios(false)}
      ></div>
      <div className={styles.menu}>
        {data?.user_studios?.map((studio) => {
          return (
            <div
              style={{
                backgroundColor: Number(id) === studio?.y_id ? "#76CEC9" : "",
              }}
              className={styles.itemMenu}
              onClick={() => {
                dispatch(clearErrorStudio());
                dispatch(clearError());
                dispatch(getStudio(studio?.y_id));
                dispatch(setStudioMenu(studio));
                navigate(`/complaints/${studio?.y_id}`);
                setOpenStudios(false);
              }}
              key={studio?.id}
            >
              {studio?.y_title}
            </div>
          );
        })}
        {data?.user_networks?.length > 0 && (
          <hr className={styles.horizontalLine} />
        )}
        {data?.user_networks?.map((studio) => {
          return (
            <div
              style={{
                backgroundColor: Number(id) === studio?.y_id ? "#76CEC9" : "",
              }}
              className={styles.itemMenu}
              onClick={() => {
                dispatch(clearErrorStudio());
                dispatch(clearError());
                dispatch(getNetworks(studio?.y_id));
                dispatch(setStudioMenu(studio));
                navigate(`/complaints_network/${studio?.y_id}`);
                setOpenStudios(false);
              }}
              key={studio?.id}
            >
              {studio?.y_title}
            </div>
          );
        })}
      </div>
    </>
  );
};

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getStudioMenuSelector } from "../../store/studios/selectors";
import IconQuality from "../../images/iconQuality.svg";
import IconArrowUp from "../../images/iconArrowUp.svg";
import IconArrowDown from "../../images/iconArrowDown.svg";
import IconSalary from "../../images/iconSalary.svg";
import IconSettings from "../../images/iconSettings.svg";
import styles from "./styles.module.css";

export const SidebarStudios = ({
  pageId,
  openMenu,
  openSidebar,
  openQuality,
  setOpenQuality,
  openSalaries,
  setOpenSalaries,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  const studioMenuTitle = useSelector(getStudioMenuSelector);

  return openSidebar ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0 12px",
      }}
    >
      <div key="quality" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openQuality && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenQuality(!openQuality);
            setOpenSalaries(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={IconQuality}
                alt="Качество"
                style={{ width: "22px", height: "22px" }}
              />
            </div>
            <span>Качество</span>
          </div>
          {!openQuality ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openQuality && (
          <div
            style={{
              padding: "14px 0 23px 18px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor: openQuality && "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "complaints" && styles.customLinkSelected
              }`}
              to={`/complaints/${studioMenuTitle?.y_id}`}
            >
              Жалобы
            </Link>
          </div>
        )}
      </div>
      <div key="salaries" className={`${styles.customMenuItem}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: openSalaries && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenSalaries(!openSalaries);
            setOpenQuality(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={IconSalary} alt="Зарплаты" />
            </div>
            <span>Зарплаты</span>
          </div>
          {!openSalaries ? (
            <img src={IconArrowDown} alt="arrowDown" />
          ) : (
            <img src={IconArrowUp} alt="arrowUp" />
          )}
        </div>
        {openSalaries && (
          <div
            style={{
              padding: "14px 0 23px 18px",
              display: "flex",
              flexDirection: "column",
              gap: 6,
              backgroundColor:
                (pageId === "corrections" || pageId === "payments") &&
                "#FFFFFF",
              borderRadius: 2,
            }}
          >
            <Link
              className={`${styles.customLink} ${
                pageId === "corrections" && styles.customLinkSelected
              }`}
              to={`/corrections/${studioMenuTitle?.y_id}`}
            >
              Коррекции
            </Link>
            <Link
              className={`${styles.customLink} ${
                pageId === "payments" && styles.customLinkSelected
              }`}
              to={`/payments/${studioMenuTitle?.y_id}`}
            >
              Выплаты
            </Link>
          </div>
        )}
      </div>
      <Link
        key="settings"
        className={`${styles.customMenuItem}`}
        to={"/settings"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 15px 10px 13px",
            backgroundColor: !openQuality && !openSalaries && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
          onClick={() => {
            setOpenSalaries(false);
            setOpenQuality(false);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div
              style={{
                width: "22px",
                height: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={IconSettings} alt="Настройки" />
            </div>
            <span style={{ color: "black" }}>Настройки</span>
          </div>
        </div>
      </Link>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        padding: "10px 0 0",
      }}
    >
      <div
        key="quality"
        onMouseEnter={() => handleMouseEnter("quality")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor:
              (pageId === "complaints" || pageId === "complaintsNetwork") &&
              "#5DCBC499",
            borderRadius: "2px 2px 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconQuality} alt="Качество" />
          </div>
        </div>
        {openMenu === "quality" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "quality" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "quality" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "complaints" && styles.customLinkSelected
                }`}
                to={`/complaints/${studioMenuTitle?.y_id}`}
              >
                Жалобы
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="salaries"
        onMouseEnter={() => handleMouseEnter("salaries")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor:
              (pageId === "corrections" || pageId === "payments") &&
              "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconSalary} alt="Зарплаты" />
          </div>
        </div>
        {openMenu === "salaries" && (
          <div className={styles.subMenuItemContainer}>
            <div
              className={`${
                openMenu === "salaries" && styles.subMenuItemAcive
              } ${styles.subMenuItem}`}
              style={{
                backgroundColor: openMenu === "salaries" && "#FFFFFF",
              }}
            >
              <Link
                className={`${styles.customLink} ${
                  pageId === "corrections" && styles.customLinkSelected
                }`}
                to={`/corrections/${studioMenuTitle?.y_id}`}
              >
                Коррекции
              </Link>
              <Link
                className={`${styles.customLink} ${
                  pageId === "payments" && styles.customLinkSelected
                }`}
                to={`/payments/${studioMenuTitle?.y_id}`}
              >
                Выплаты
              </Link>
            </div>
          </div>
        )}
      </div>
      <div
        key="settings"
        onMouseEnter={() => handleMouseEnter("settings")}
        onMouseLeave={handleMouseLeave}
        className={`${styles.customMenuItem}`}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0",
            backgroundColor: pageId === "settings" && "#5DCBC499",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={IconSettings} alt="Настройки" />
          </div>
        </div>
        {openMenu === "settings" && (
          <div className={styles.subMenuItemContainer}>
            <Link
              className={`${styles.customLink} ${
                pageId === "settings" && styles.customLinkSelected
              }`}
              to={`/settings`}
            >
              Настройки
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
